.loandata{
  background-image: url('https://loanifystoragedev.blob.core.windows.net/assest/dealers-portal-bg.jpg');
  background-size: cover;
  background-repeat: no-repeat;
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.card-header{
  margin: -24px;
  text-align: center;
  padding-top: 16px;
  background-color: #c8c8c8;

}
.title{
font-size: 22px;
font-weight: 400;
padding: 0;
}

.card-logo{
  width: auto;
  height: 58px;
}

.card-footer{
  display: flex;
  justify-content: center;
  align-items: center;
 margin: 0 -24px -24px -24px;
 background-color: #0070c0;
  
}
.footer-button{
  height: 60px;
  background-color: #0070c0;
  color: white;
  display: block;
  width: 100%;
  border: none;
  font-size: 14px;
  border-color: #0070c0;
}
.footer-button:hover{
  font-size: 16px;
  color: white !important;
  border: none ;
}