.bg{
    background-image: 
    url("https://loanifystoragedev.blob.core.windows.net/assest/dealers-portal-bg.jpg");
background-repeat: no-repeat, no-repeat;
background-position: right, left; 
margin: 80px;
}

.outer-container {
    background-image: url("https://loanifystoragedev.blob.core.windows.net/assest/dealers-portal-bg.jpg"); /* Set the background image */
    background-size: cover;
    background-position: center;
    min-height: 100vh; /* Ensure the background covers the entire viewport */
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .inner-container {
    margin-top: 50px;
    margin-bottom: 50px;
    background-color: rgba(255, 255, 255, 0.8); /* Background color for the inner div */
    padding: 20px;
    border-radius: 10px;
    width: 1200px;
    /* Add more styling as needed */
  }

  .ant-form-item {
    font-weight: bold;
    width: 100%;
}

.ant-input-group-addon{
  background-color: white !important;
}