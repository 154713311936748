.upload-block{
    background-color: #F3F3F3 ;
    padding:5px 15px;
}

.title{
    font-weight: 600;
    font-size: large;
    padding: 2px;

}
.upload{
    margin-top: 5px;
}

.req label::before{
    content:" * ";
    color: red;
}