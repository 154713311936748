.ant-form-item-required .ant-form-item {
    font-weight: bold;
}

.form-static-fields> input{

    width: 100%;
    border: none;
    box-shadow: none;
    color: inherit;
    padding: 0 !important;
    background-color: #f3f3f3;

}